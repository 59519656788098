import { FC, StrictMode, useEffect } from 'react';

import { InstUISettingsProvider } from '@instructure/emotion';
import { canvas as canvasBaseTheme, canvasHighContrast as canvasHighContrastTheme } from '@instructure/ui-themes';

import { AppInitContextProvider } from './app-loader/components/AppInitContextProvider';
import { useLoadCanvasParameters } from './app-loader/hooks/useLoadCanvasParameters';
import { useLoadLtiAppDefinitions } from './app-loader/hooks/useLoadLtiAppDefinitions';
import { useLoadCanvasToken } from './app-loader/hooks/useLoadCanvasToken';

import { AppLoader } from '@insights-analytics/feature-app-loader';
import { setupI18n } from '@insights-analytics/feature-translation';
import { config } from '../config';
import AnalyticsHub from '../analytics-hub/AnalyticsHub';
import { useLoadExternalFeatures } from './app-loader/hooks/useLoadExternalFeatures';
import { useLoadLanguage } from './app-loader/hooks/useLoadLanguage';
import styles from './app.module.scss';
import '../styles/styles.scss';

const App: FC = () => {
    useEffect(() => {
        const i18n = setupI18n('analytics-hub');
        i18n.init({
            ns: ['moduleMain'],
            defaultNS: 'moduleMain',
            fallbackNS: 'moduleMain',
            fallbackLng: 'en',
        });
    }, []);

    const theme = window.ENV?.use_high_contrast
        ? canvasHighContrastTheme
        : {
              ...canvasBaseTheme,
              ...window.CANVAS_ACTIVE_BRAND_VARIABLES,
          };

    return (
        <StrictMode>
            <InstUISettingsProvider theme={theme}>
                <div className={styles.analyticsHubContainer} id="analytics-hub-container">
                    <AppInitContextProvider>
                        <AppLoader
                            processes={[
                                useLoadCanvasParameters,
                                useLoadLtiAppDefinitions,
                                useLoadCanvasToken,
                                useLoadExternalFeatures,
                                useLoadLanguage,
                            ]}
                            minimumLoadingTime={config.minimumLoadingTime}
                        >
                            <AnalyticsHub />
                        </AppLoader>
                    </AppInitContextProvider>
                </div>
            </InstUISettingsProvider>
        </StrictMode>
    );
};

export default App;
