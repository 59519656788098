import { useContext, useEffect, useState } from 'react';

import { LoadingProcessStatus, LoadingProcess } from '@insights-analytics/feature-app-loader';

import { AppInitContext } from '../components/AppInitContextProvider';
import { permissions } from '../../../common/permissions';

export type CanvasParameters = {
    accountId: string;
    permissions: permissions[];
    feature_advanced_analytics_ask_questions_enabled: boolean;
    feature_k20_students_in_need_of_attention_enabled: boolean;
    feature_k20_course_readiness_enabled: boolean;
    feature_k20_lti_usage_enabled: boolean;
    locale: string;
};

export function useLoadCanvasParameters(): LoadingProcess {
    const [status, setStatus] = useState<LoadingProcessStatus>('loading');

    const { setCanvasParameters } = useContext(AppInitContext);

    useEffect(() => {
        const { ANALYTICS_HUB } = window.ENV || {};
        const accountId = ANALYTICS_HUB?.ACCOUNT_ID || window.ENV?.accountID;
        if (!accountId) {
            console.error('Failed to load Canvas parameters: ACCOUNT_ID not found in window.ENV');
            setStatus('failure');
            return;
        }

        const permissions: permissions[] = ANALYTICS_HUB?.PERMISSIONS || window.ENV?.permissions;
        if (!permissions) {
            console.error('Failed to load Canvas parameters: PERMISSIONS not found in window.ENV');
            setStatus('failure');
            return;
        }

        const { FEATURE_FLAGS } = ANALYTICS_HUB || {};

        const feature_advanced_analytics_ask_questions_enabled =
            FEATURE_FLAGS?.ADVANCED_ANALYTICS_ASK_QUESTIONS_ENABLED ??
            window.ENV?.feature_advanced_analytics_ask_questions_enabled;
        const feature_k20_students_in_need_of_attention_enabled =
            FEATURE_FLAGS?.K20_STUDENTS_IN_NEED_OF_ATTENTION_ENABLED ??
            window.ENV?.feature_k20_students_in_need_of_attention_enabled;
        const feature_k20_course_readiness_enabled =
            FEATURE_FLAGS?.K20_COURSE_READINESS_ENABLED ?? window.ENV?.feature_k20_course_readiness_enabled;
        const feature_k20_lti_usage_enabled =
            FEATURE_FLAGS?.K20_LTI_USAGE_ENABLED ?? window.ENV?.feature_k20_lti_usage_enabled;

        if (
            feature_advanced_analytics_ask_questions_enabled === undefined ||
            feature_k20_students_in_need_of_attention_enabled === undefined ||
            feature_k20_course_readiness_enabled === undefined ||
            feature_k20_lti_usage_enabled === undefined
        ) {
            console.error('Failed to load Canvas parameters: One or more feature flags not found in window.ENV');
            setStatus('failure');
            return;
        }

        const locale = window.ENV?.LOCALE;
        if (!locale) {
            console.error('Failed to load Canvas parameters: LOCALE not found in window.ENV');
            setStatus('failure');
            return;
        }

        setCanvasParameters({
            accountId,
            permissions,
            feature_advanced_analytics_ask_questions_enabled,
            feature_k20_students_in_need_of_attention_enabled,
            feature_k20_course_readiness_enabled,
            feature_k20_lti_usage_enabled,
            locale,
        });
        setStatus('success');
    }, [setCanvasParameters]);

    return status === 'failure'
        ? {
            name: 'canvasParametersLoader',
            status: status,
            message: 'Failed to load Canvas parameters',
        }
        : {
            name: 'canvasParametersLoader',
            status: status,
        };
}
