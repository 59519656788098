import i18n, { createInstance, KeyPrefix, type i18n as I18nInstance } from 'i18next';
import { initReactI18next, useTranslation, UseTranslationOptions } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

const instanceCache: Array<I18nInstance> = [];

function setupBackendResources(appName: string) {
    return resourcesToBackend((language: string, namespace: string) => import(
        /* webpackInclude: /\.json$/ */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: false */
        /* webpackPreload: false */
        `./../../../../locales/${appName}/${language}/${namespace}.json`
    ));
}

export const setupDefaultI18n = (appName: string) => {
    return i18n
        .use(initReactI18next)
        .use(setupBackendResources(appName));
};

export const setupNewI18nInstance = (appName: string) => {
    const newInstance = createInstance();
    instanceCache.push(newInstance);

    // Sync language changes across all cached instances
    i18n.on('languageChanged', (lng) => {
        instanceCache.forEach((instance) => {
            instance.changeLanguage(lng);
        });
    });

    return newInstance
        .use(setupBackendResources(appName));
};

export const createLibTransHook = (libI18nInstance: I18nInstance) => {
    return function useHook<TNs extends string, TKey extends KeyPrefix<TNs>>(
        namespace: TNs,
        keyPrefix?: TKey,
        options?: Omit<UseTranslationOptions<TKey>, 'i18n' | 'keyPrefix'>
    ) {
        return useTranslation<TNs, TKey>(namespace,
            {
                ...options,
                i18n: libI18nInstance,
                keyPrefix: keyPrefix,
            });
    }
}

export const changeLanguage = (language: string) => {
    return i18n.changeLanguage(language);
}
