import { useContext } from 'react';

import { Flex, Text } from '@instructure/ui';

import { useTranslation } from '@insights-analytics/feature-translation';
import Card from './components/Card';
import Highlighted from './components/Highlighted';

import styles from './AnalyticsHub.module.scss';
import { useFilteredCards } from './hooks/useFilteredCards';
import { AppInitContext } from '../app/app-loader/components/AppInitContextProvider';

const AnalyticsHub = () => {
    const { t } = useTranslation('moduleMain', { useSuspense: false });
    const { ltiAppDefinitions, canvasParameters, externalFeatures } = useContext(AppInitContext);

    const cards = useFilteredCards(
        canvasParameters.accountId,
        ltiAppDefinitions,
        canvasParameters.permissions,
        externalFeatures
    );

    const showHighlighted =
        !canvasParameters.feature_advanced_analytics_ask_questions_enabled &&
        !canvasParameters.feature_k20_course_readiness_enabled &&
        !canvasParameters.feature_k20_students_in_need_of_attention_enabled;

    return (
        <Flex direction="column" padding="large x-large x-large x-large" gap="small">
            <Flex gap="x-small" direction="column">
                <Flex.Item>
                    <Text size="xx-large" weight="bold">
                        Analytics Hub
                    </Text>
                </Flex.Item>
                <Flex.Item id={styles.description}>
                    <Text size="large">{t('analytics_hub.description')}</Text>
                </Flex.Item>
                <Flex.Item>{showHighlighted && <Highlighted />}</Flex.Item>
            </Flex>
            <Flex id={styles.card} gap="medium" margin="medium 0" wrap="wrap">
                {cards.map((card) => !!card.menus.length && <Card card={card} key={card.title} />)}
            </Flex>
        </Flex>
    );
};

export default AnalyticsHub;
