import { useEffect, useMemo, useState } from 'react';
import { canvas as CanvasTheme } from '@instructure/ui';

/**
 *
 * @param selector the selector of the container element to match size with
 * @param breakpoints use custom breakpoints or default to CanvasTheme.breakpoints to search on
 * @returns the matching breakpoint size in rem
 */
export const useContainerBreakpoint = (selector: string, breakpoints?: Record<string, string>) => {
    const breakpointValues = useMemo(() => [...Object.values(breakpoints || CanvasTheme.breakpoints)], [breakpoints]);
    const [breakpoint, setBreakpoint] = useState<string>(breakpointValues[0]);

    const getRemSize = (entries: ResizeObserverEntry[]) => {
        const { inlineSize } = entries[0].contentBoxSize[0];
        const rem = window.getComputedStyle(document.body).fontSize;
        return inlineSize / parseFloat(rem);
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            const rootRemSize = getRemSize(entries);
            const actualBreakpoint = breakpointValues.find((breakpoint, index) => {
                if (index === 0) {
                    return rootRemSize <= parseInt(breakpoint);
                } else if (index === breakpointValues.length - 1) {
                    return rootRemSize > parseInt(breakpoint);
                }
                return rootRemSize <= parseInt(breakpoint) && rootRemSize > parseInt(breakpointValues[index - 1]);
            });
            if (actualBreakpoint) {
                setBreakpoint(actualBreakpoint);
            }
        });

        resizeObserver.observe(document.querySelector(selector) as HTMLElement);

        return () => {
            resizeObserver.disconnect();
        };
    }, [selector, breakpointValues, breakpoints]);

    return breakpoint;
};
