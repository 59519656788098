import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { featureFlagStoreFactory, AllFeatureFlags, FeatureFlags } from '@insights-analytics/feature-feature-flags';

declare global {
    interface Window {
        setFeatureFlag: (featureName: string, value: boolean) => void;
    }
}

type FeatureFlagsContext = {
    featureFlags: FeatureFlags;
};

type FeatureFlagProviderProps<T extends string> = {
    appName: string;
    env: T;
    defaultFFs: AllFeatureFlags<T>;
    children: ReactNode;
};

const FeatureFlagsContext = createContext({} as FeatureFlagsContext);

export function FeatureFlagProvider<T extends string>({
    appName,
    env,
    defaultFFs,
    children,
}: FeatureFlagProviderProps<T>) {
    const { setFeatureFlag, getFeatureFlags } = useMemo(
        () => featureFlagStoreFactory(appName, env, defaultFFs),
        [appName, env, defaultFFs]
    );

    const [featureFlags, setFeatureFlags] = useState<FeatureFlags>({
        ...defaultFFs[env],
        ...getFeatureFlags(),
    });

    const storeAndSetFlag = useCallback(
        (featureName: string, value: boolean) => {
            const newFFs = setFeatureFlag(featureFlags, featureName, value);
            setFeatureFlags(newFFs);
        },
        [featureFlags, setFeatureFlag, setFeatureFlags]
    );

    useEffect(() => {
        window.setFeatureFlag = storeAndSetFlag;
    }, [storeAndSetFlag]);

    return <FeatureFlagsContext.Provider value={{ featureFlags }}>
        {children}
    </FeatureFlagsContext.Provider>;
}

export const useFeatureFlagsContext = () => useContext(FeatureFlagsContext);
