import { useContext, useEffect, useState } from 'react';

import { LoadingProcess, LoadingProcessStatus } from '@insights-analytics/feature-app-loader';
import { config } from '../../../config';
import { AppInitContext } from '../components/AppInitContextProvider';
import { apiClientFactory } from '../../../common/api-client';

export type ExternalFeature = {
    name: string;
    enabled: boolean;
};

// Some apps can only be accessed if it is enabled in the external application
// This hook fetches external tools data
// Right now it is only used for Mastery Connect (SNAIL)
export function useLoadExternalFeatures(): LoadingProcess {
    const [status, setStatus] = useState<LoadingProcessStatus>('loading');
    const { ltiAppDefinitions, ltiAppDefinitionsLoaded, canvasToken, canvasTokenLoaded, setExternalFeatures } =
        useContext(AppInitContext);

    useEffect(() => {
        if (!ltiAppDefinitionsLoaded || !canvasTokenLoaded) {
            return;
        }

        const fetchData = async () => {
            try {
                const masteryConnectLaunchUrl = ltiAppDefinitions['Mastery Connect']?.url;

                if (!masteryConnectLaunchUrl) {
                    console.log('Mastery Connect LTI is not installed.');
                    setStatus('non-blocking-failure');
                    return [];
                }

                const masteryConnectBaseUrl = `${masteryConnectLaunchUrl.protocol}//${masteryConnectLaunchUrl.hostname
                    }${masteryConnectLaunchUrl.port ? ':' + masteryConnectLaunchUrl.port : ''}`;

                const apiClient = apiClientFactory({
                    baseUrl: masteryConnectBaseUrl,
                    headers: { Authorization: `Bearer ${canvasToken}` },
                });

                const response = await apiClient.get<Array<ExternalFeature>>(config.masteryConnectFeaturesApiPath);
                setStatus('success');
                return response.data;
            } catch (error) {
                console.error('Failed to fetch external tools data:', error);
                setStatus('non-blocking-failure');
                return [];
            }
        };

        fetchData().then((data) => {
            setExternalFeatures(data);
        });
    }, [setExternalFeatures, ltiAppDefinitions, ltiAppDefinitionsLoaded, canvasTokenLoaded, canvasToken]);

    return {
        name: 'externalFeatureLoader',
        status: status,
    };
}
