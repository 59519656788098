import axios from 'axios';

type ApiClientFactoryOptions = {
    baseUrl?: string;
    headers?: Record<string, string>;
};

export const apiClientFactory = ({ baseUrl = '', headers }: ApiClientFactoryOptions = {}) => {
    return axios.create({
        baseURL: `${window.ENV?.ANALYTICS_HUB?.CANVAS_API_BASE_URL || baseUrl}`,
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        }
    });
}
