import { createContext, useState } from 'react';
import { LtiAppDefinition } from '../hooks/useLoadLtiAppDefinitions';
import { CanvasParameters } from '../hooks/useLoadCanvasParameters';
import { ExternalFeature } from '../hooks/useLoadExternalFeatures';
import { ltiAppNames } from '../../../common/ltiAppNames';

export type AppInitData = {
    ltiAppDefinitions: Record<ltiAppNames, LtiAppDefinition>;
    setLtiAppDefinitions: (ltiAppDefs: Record<ltiAppNames, LtiAppDefinition>) => void;
    ltiAppDefinitionsLoaded: boolean;
    canvasParameters: CanvasParameters;
    canvasParametersLoaded: boolean;
    setCanvasParameters: (canvasParams: CanvasParameters) => void;
    canvasToken: string | null;
    setCanvasToken: (token: string | null) => void;
    canvasTokenLoaded: boolean;
    externalFeatures: ExternalFeature[];
    setExternalFeatures: (features: ExternalFeature[]) => void;
};

const AppInitContext = createContext({} as AppInitData);
const AppInitContextProvider = ({ children }: { children: React.ReactElement }) => {
    const [contextData, setContextData] = useState<AppInitData>({
        ltiAppDefinitions: {} as Record<ltiAppNames, LtiAppDefinition>,
        setLtiAppDefinitions: (ltiAppDefs) => {
            setContextData((prev) => ({ ...prev, ltiAppDefinitions: ltiAppDefs, ltiAppDefinitionsLoaded: true }));
        },
        ltiAppDefinitionsLoaded: false,
        canvasParameters: {
            accountId: '',
            permissions: [],
            feature_advanced_analytics_ask_questions_enabled: false,
            feature_k20_students_in_need_of_attention_enabled: false,
            feature_k20_course_readiness_enabled: false,
            feature_k20_lti_usage_enabled: false,
            locale: '',
        },
        canvasParametersLoaded: false,
        setCanvasParameters: (canvasParams) => {
            setContextData((prev) => ({ ...prev, canvasParameters: canvasParams, canvasParametersLoaded: true }));
        },
        canvasToken: null,
        setCanvasToken: (token) => {
            setContextData((prev) => ({ ...prev, canvasToken: token, canvasTokenLoaded: true }));
        },
        canvasTokenLoaded: false,
        externalFeatures: [],
        setExternalFeatures: (features) => {
            setContextData((prev) => ({ ...prev, externalFeatures: features }));
        },
    });

    return <AppInitContext.Provider value={contextData}>{children}</AppInitContext.Provider>;
};

export { AppInitContextProvider, AppInitContext };
