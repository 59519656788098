import { setupNewI18nInstance, createLibTransHook } from '@insights-analytics/feature-translation';

const i18n = setupNewI18nInstance('feature-app-loader');
i18n.init({
    ns: ['moduleAppLoader'],
    defaultNS: 'moduleAppLoader',
    fallbackNS: 'moduleAppLoader',
    fallbackLng: 'en',
});

export const useLibTrans = createLibTransHook(i18n);

