import { useState, FC } from 'react';
import { Flex, Text, Button, canvas as CanvasTheme } from '@instructure/ui';
import { useLocalStorage } from '@uidotdev/usehooks';

import { useTranslation } from '@insights-analytics/feature-translation';
import MaskIcon from '../../assets/images/mask.svg';
import EyeIcon from '../../assets/images/eye.svg';
import PremiumIcon from '../../assets/images/premium.svg';
import ExternalIcon from '../../assets/images/external.svg';

import { isSizeMatched, useContainerBreakpoint } from '@insights-analytics/utils-react';

import { logoMap } from './Card';
import { intelligentInsights } from '../cards';
import styles from './Highlighted.module.scss';

const Highlighted: FC = () => {
    const [hideHighlighted, setHideHighlighted] = useLocalStorage('analyticsHub_hideHighlighted', false);
    const [descriptionOpen, setDescriptionOpen] = useState(false);

    const containerBreakpoint = useContainerBreakpoint('#analytics-hub-container');
    const isSmallDevice = isSizeMatched(containerBreakpoint, CanvasTheme.breakpoints.small);
    const isMediumDevice = isSizeMatched(containerBreakpoint, CanvasTheme.breakpoints.medium);

    const { t } = useTranslation('moduleMain', { useSuspense: false });

    return (
        <Flex gap="xx-small" id={styles.insightsWrapper}>
            <Flex direction="column" gap="large" id={styles.insightsBox}>
                <Flex gap="xx-small">
                    {hideHighlighted ? <EyeIcon /> : <MaskIcon />}
                    <Flex.Item onClick={() => setHideHighlighted((prevState) => !prevState)} id={styles.highlights}>
                        <Text id={styles.opener}>
                            {hideHighlighted
                                ? t('analytics_hub.highlighted.button.show.text')
                                : t('analytics_hub.highlighted.button.hide.text')}
                        </Text>
                    </Flex.Item>
                </Flex>
                {!hideHighlighted && (
                    <div className={styles.blockWrapper}>
                        <div className={styles.block}>
                            <Flex gap="xx-small" id={styles.upgrade} as="a" href="upgrade">
                                <PremiumIcon className={styles.premiumIcon} />
                                <Text>{t('analytics_hub.highlighted.upgrade_feature_badge.text')}</Text>
                            </Flex>
                            <Flex gap="small" direction="column" id={styles.cardContent}>
                                <Text id={styles.insightsTitle}>Intelligent Insights</Text>
                                <Text
                                    id={
                                        isSmallDevice && !descriptionOpen
                                            ? styles.insightsDescriptionSmall
                                            : styles.insightsDescription
                                    }
                                >
                                    {t('analytics_hub.highlighted.description')}
                                </Text>
                            </Flex>
                            {isSmallDevice && (
                                <div
                                    className={styles.showMore}
                                    onClick={() => setDescriptionOpen((prevState) => !prevState)}
                                >
                                    {descriptionOpen ? t('less') : `...${t('more')}`}
                                </div>
                            )}
                            <Flex
                                id={styles.cardWrapper}
                                direction={isMediumDevice ? 'column' : 'row'}
                                gap={isSmallDevice ? 'medium' : 'small'}
                            >
                                {intelligentInsights.map(({ title, logo }) => (
                                    <Flex.Item key={title} id={styles.card}>
                                        <Flex gap="xx-small">
                                            {logoMap[logo]}
                                            <Text id={styles.cardTitle}>{t(title)}</Text>
                                        </Flex>
                                    </Flex.Item>
                                ))}
                            </Flex>
                            <Button
                                href="https://community.canvaslms.com/t5/Intelligent-Insights/ct-p/intelligent_insights"
                                renderIcon={<ExternalIcon />}
                                id={styles.learn}
                                size="small"
                                wrap="wrap"
                                target="_blank"
                            >
                                <Text>{t('analytics_hub.highlighted.button.learn_more.text')}</Text>
                            </Button>
                        </div>
                    </div>
                )}
            </Flex>
        </Flex>
    );
};

export default Highlighted;
