import { FC } from 'react';
import { Flex, Popover, Text, View, canvas as CanvasTheme } from '@instructure/ui';

import { useTranslation } from '@insights-analytics/feature-translation';
import AnalyticsIcon from '../../assets/images/analytics.svg';
import UserIcon from '../../assets/images/user.svg';
import OutcomesIcon from '../../assets/images/outcomes.svg';
import PredictiveIcon from '../../assets/images/predictive.svg';
import AssignmentIcon from '../../assets/images/assignment.svg';
import SisSyncedIcon from '../../assets/images/sissynced.svg';
import PremiumIcon from '../../assets/images/premium.svg';
import ChevronIcon from '../../assets/images/chevron.svg';
import BookIcon from '../../assets/images/book.svg';

import { permissions } from '../../common/permissions';
import { allAppNames, ltiAppNames } from '../../common/ltiAppNames';

import { isSizeMatched, useContainerBreakpoint } from '@insights-analytics/utils-react';

import styles from './Card.module.scss';

type productAnalyticsEventId = `launch-${allAppNames}-${string}`;

type logoType = keyof typeof logoMap;

export type CardMenu = {
    title: string;
    href: string;
    openOnNewTab?: boolean;
    intelligentInsights?: boolean;
    popoverDescription?: string;
    ltiAppName?: ltiAppNames;
    permission?: permissions;
    externalFeatureName?: string;
    productAnalyticsId?: productAnalyticsEventId;
};

export type Card = {
    title: string;
    description: string;
    logo: logoType;
    menus: CardMenu[];
};

export type CardProps = {
    card: Card;
};

export const logoMap = {
    analytics: <AnalyticsIcon />,
    user: <UserIcon />,
    outcomes: <OutcomesIcon />,
    predictive: <PredictiveIcon />,
    assignment: <AssignmentIcon />,
    sisSynced: <SisSyncedIcon />,
    book: <BookIcon />,
} as const;

const Card: FC<CardProps> = ({ card }) => {
    const { title, description, logo, menus } = card;
    const containerBreakpoint = useContainerBreakpoint('#analytics-hub-container');
    const isSmallDevice = isSizeMatched(containerBreakpoint, CanvasTheme.breakpoints.small);

    const { t } = useTranslation('moduleMain', { useSuspense: false });

    return (
        <Flex.Item id={styles.cardWrapper} shouldGrow>
            <div className={styles.header} />
            <div className={styles.card}>
                <Flex direction="column" gap="xxx-small" padding="xx-small">
                    <Flex gap="xx-small" alignItems="center">
                        <Flex.Item id={styles.logo}>{logoMap[logo]}</Flex.Item>
                        <Flex.Item>
                            <Text size="small" weight="bold">
                                {t(title)}
                            </Text>
                        </Flex.Item>
                    </Flex>
                    {!isSmallDevice && (
                        <Text id={styles.description} size="x-small">
                            {t(description)}
                        </Text>
                    )}
                    <div className={styles.divider} />
                    <Flex direction="column" id={styles.links}>
                        {menus.map(
                            ({
                                title: menuTitle,
                                href,
                                intelligentInsights,
                                openOnNewTab,
                                popoverDescription,
                                productAnalyticsId,
                            }) => (
                                <Flex gap="xxx-small" alignItems="center" key={t(menuTitle)} id={styles.menuItem}>
                                    {popoverDescription ? (
                                        <Popover
                                            renderTrigger={
                                                <span>
                                                    <PremiumIcon className={styles.premiumIcon} />
                                                </span>
                                            }
                                            shouldRenderOffscreen
                                            shouldCloseOnEscape
                                            offsetY={2}
                                            color="primary"
                                            withArrow
                                            shouldAlignArrow
                                            placement="top center"
                                        >
                                            <View
                                                padding="small"
                                                color="primary"
                                                display="block"
                                                as="div"
                                                id="tip"
                                                width="22rem"
                                            >
                                                <Text size="small">{`${t(menuTitle)} ${t(popoverDescription)}`}</Text>
                                            </View>
                                        </Popover>
                                    ) : (
                                        intelligentInsights && <PremiumIcon className={styles.premiumIcon} />
                                    )}
                                    <Text
                                        data-analytics={productAnalyticsId}
                                        size="small"
                                        id={styles.menuTitle}
                                        as="a"
                                        href={href}
                                        target={openOnNewTab ? '_blank' : '_self'}
                                    >
                                        {t(menuTitle)}
                                    </Text>
                                    <Flex.Item id={styles.chevron}>
                                        <ChevronIcon />
                                    </Flex.Item>
                                </Flex>
                            )
                        )}
                    </Flex>
                </Flex>
            </div>
        </Flex.Item>
    );
};

export default Card;
