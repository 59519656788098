import { useState, useEffect, useContext } from 'react';
import { LoadingProcessStatus, LoadingProcess } from '@insights-analytics/feature-app-loader';
import { AppInitContext } from '../components/AppInitContextProvider';
import { ltiAppNames } from '../../../common/ltiAppNames';
import { ltiAppRegExps } from '../../../common/ltiAppRegExps';
import { apiClientFactory } from '../../../common/api-client';

export type LtiAppDefinitionDto = {
    id: number;
    name: string;
    url: string;
};

export type LtiAppDefinition = {
    id: number;
    url: URL;
};

export function useLoadLtiAppDefinitions(): LoadingProcess {
    const [status, setStatus] = useState<LoadingProcessStatus>('loading');
    const { canvasParameters, canvasParametersLoaded, setLtiAppDefinitions } = useContext(AppInitContext);

    useEffect(() => {
        if (!canvasParametersLoaded) {
            return;
        }

        const pageSize = 100;
        const fetchData = async () => {
            try {
                const queryParams = {
                    include_parents: true,
                    per_page: pageSize,
                    page: 1,
                };

                const apiClient = apiClientFactory({});
                const allData = {} as Record<ltiAppNames, LtiAppDefinition>;
                let currDataLength = 0;

                do {
                    const response = await apiClient.get<Array<LtiAppDefinitionDto>>(
                        `/api/v1/accounts/${canvasParameters.accountId}/external_tools`,
                        { params: queryParams }
                    );
                    currDataLength = response.data.length;

                    response.data.forEach((ltiAppDefinition) => {
                        let url: URL;
                        try {
                            url = new URL(ltiAppDefinition.url);
                        } catch (error) {
                            console.log(
                                'Invalid app url found in the returned LTI app list',
                                ltiAppDefinition.url,
                                error
                            );
                            return;
                        }

                        ltiAppRegExps.forEach((regExps, key) => {
                            if (regExps.some((regexp) => regexp.test(url.toString()))) {
                                allData[key] = {
                                    id: ltiAppDefinition.id,
                                    url,
                                };
                            }
                        });
                    });

                    queryParams.page += 1;
                } while (currDataLength >= pageSize);

                console.log('LtiAppDefinitions', allData);
                setStatus('success');
                return allData;
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setStatus('failure');
                return {} as Record<ltiAppNames, LtiAppDefinition>;
            }
        };

        fetchData().then((data) => {
            setLtiAppDefinitions(data);
        });
    }, [canvasParameters.accountId, canvasParametersLoaded, setLtiAppDefinitions]);

    return status === 'failure'
        ? {
            name: 'ltiAppDefinitionLoader',
            status: status,
            message: 'Failed to fetch LTI data from Canvas',
        }
        : {
            name: 'ltiAppDefinitionLoader',
            status: status,
        };
}
