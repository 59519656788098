import { permissions } from '../common/permissions';

export type ENV = {
    accountID?: string;
    permissions?: permissions[];
    feature_advanced_analytics_ask_questions_enabled?: boolean;
    feature_k20_students_in_need_of_attention_enabled?: boolean;
    feature_k20_course_readiness_enabled?: boolean;
    feature_k20_lti_usage_enabled?: boolean;
    LOCALE: string;
    use_high_contrast: boolean;
    ANALYTICS_HUB: {
        ACCOUNT_ID: string;
        PERMISSIONS: permissions[];
        FEATURE_FLAGS: {
            ADVANCED_ANALYTICS_ASK_QUESTIONS_ENABLED: boolean;
            K20_STUDENTS_IN_NEED_OF_ATTENTION_ENABLED: boolean;
            K20_COURSE_READINESS_ENABLED: boolean;
            K20_LTI_USAGE_ENABLED: boolean;
        };
        CANVAS_API_BASE_URL: string;
    };
};

export interface Config {
    minimumLoadingTime: number;
    masteryConnectFeaturesApiPath: string;
}

function initConfig(): Config {
    return {
        minimumLoadingTime: 1000,
        masteryConnectFeaturesApiPath: '/api/analytics/features',
    };
}

export const config = initConfig();
