import { useState, useEffect, useContext } from 'react';

import { useCookies } from 'react-cookie';

import { LoadingProcessStatus, LoadingProcess } from '@insights-analytics/feature-app-loader';
import { AppInitContext } from '../components/AppInitContextProvider';
import { apiClientFactory } from '../../../common/api-client';

export type CanvasTokenResponse = {
    token: string;
};

export function useLoadCanvasToken(): LoadingProcess {
    const [status, setStatus] = useState<LoadingProcessStatus>('loading');
    const { setCanvasToken } = useContext(AppInitContext);

    const [cookies] = useCookies(['_csrf_token']);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiClient = apiClientFactory({
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'X-CSRF-Token': cookies._csrf_token,
                    },
                });

                const response = await apiClient.post<CanvasTokenResponse>('/api/v1/jwts', {
                    canvas_audience: false,
                });

                setStatus('success');
                return response.data;
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setStatus('failure');
                return { token: null };
            }
        };

        fetchData().then((data) => {
            setCanvasToken(data.token);
        });
    }, []);

    return status === 'failure'
        ? {
            name: 'canvasTokenLoader',
            status: status,
            message: 'Failed to fetch Canvas token',
        }
        : {
            name: 'canvasTokenLoader',
            status: status,
        };
}
