import { useContext, useEffect, useState } from 'react';

import { LoadingProcessStatus, LoadingProcess } from '@insights-analytics/feature-app-loader';
import { changeLanguage } from '@insights-analytics/feature-translation';
import { AppInitContext } from '../components/AppInitContextProvider';

export function useLoadLanguage(): LoadingProcess {
    const [status, setStatus] = useState<LoadingProcessStatus>('loading');
    const { canvasParametersLoaded, canvasParameters } = useContext(AppInitContext);

    useEffect(() => {
        if (!canvasParametersLoaded) {
            return;
        }

        const loadLanguage = async () => {
            try {
                await changeLanguage(canvasParameters.locale.replace('_', '-'));
                setStatus('success');
            } catch (err) {
                console.error('Failed to change language (using fallback language):', err);
                setStatus('non-blocking-failure');
            }
        };

        loadLanguage();
    }, [canvasParametersLoaded, canvasParameters]);

    return {
        name: 'hostLoader',
        status: status,
        ...(status === 'failure' && { message: 'Failed to load language' })
    };
}
