import { createRoot } from 'react-dom/client';

import App from '../app/app';
import '@insights-analytics/feature-translation';

interface Root {
    render(component: JSX.Element): void;
    unmount(): void;
}

let root: Root | null = null;

export const render = (container: HTMLElement): void => {
    root = createRoot(container);
    root.render(<App />);
};

export const unmount = (): void => {
    root?.unmount();
    root = null;
};
