import { ltiAppNames } from './ltiAppNames';

// Ask Your Data
// https://app.doowii.io
// https://canvas-uat.doowii.io --not matched now

// Admin Analytics
// https://canvas-analytics-iad-prod.inscloudgate.net/k20
// https://canvas-analytics-iad-stage.inscloudgate.net/k20 --not matched now
// https://canvas-analytics-iad-beta.inscloudgate.net/k20 --not matched now

// Mastery Connect
// https://app.masteryconnect-staging.com
// https://app.masteryconnect-security.com
// https://app.masteryconnect-mars.com
// https://app.masteryconnect-performance.com
// https://app.masteryconnect.com
// https://*.mstry.click

// Impact Dashboard LTI 1.3
// what we only know is that it ends with eesysoft.com and the concrete url can be queried at '/api/v1/impact/config'
// https://*.eesysoft.com

// LTI Usage LTI 1.3
// https://lti-usage-api.impact.inseng.net
// https://lti-usage-api-iad.beta.impact.instructure.com

export const ltiAppRegExps = new Map<ltiAppNames, RegExp[]>([
    ['Ask Your Data', [/^https:\/\/(www\.)?app\.doowii\.io/]],
    ['Admin Analytics', [/^https:\/\/(www\.)?canvas-analytics-iad-prod\.inscloudgate\.net\/k20/]],
    ['Mastery Connect', [/^https:\/\/(www\.)?app\.masteryconnect(.*)\.com/, /^(.*)\.mstry\.click/]],
    ['Impact Dashboard LTI 1.3', [/^https:\/\/(.*)\.eesysoft\.com/]],
    ['LTI Usage LTI 1.3', [/^https:\/\/(www\.)?lti-usage-api(.*)/]],
]);
